.page-header {
  @include wp-full-width-no-padding;
  background: var(--gradient-purple-light);
  margin-bottom: var(--space-lg);
  .page &,.single & {
    margin-bottom: var(--space-xl);
  }
  &.has-featured-image {
    padding-bottom: var(--space-2xl);
    margin-bottom: 0;
  }
  .details {
    @include last-child-zero-margin;
    margin-top: var(--space-md);
    margin-bottom: var(--space-lg);
    font-size: var(--text-2xl);
    text-align: center;
    color: var(--color-purple);
    .single-post & {
      text-align: inherit;
      font-size: var(--text-xl);
      color: var(--color-gray);
      .subtitle {
        @include last-child-zero-margin;
      }
    }
    .overline {
      @include overline;
      margin-bottom: var(--space-sm);
      font-size: var(--text-base);
      color: var(--color-brand-secondary);
    }
    p.categories {
      a {
        @include link-no-underline;
        @include link-color-brand-secondary;
      }
    }
    h1 {
      font-size: var(--text-6xl);
      color: var(--color-brand-primary);
      margin: 0 0 var(--space-sm);
    }
    .author-byline {
      margin-top: var(--space-sm);
      .avatar {
        margin-right: var(--space-xs);
        figure {
          @include last-child-zero-margin;
          width: var(--avatar-size);
          margin-bottom: 0;
          aspect-ratio: 1/1;
          overflow: hidden;
          border: 2px solid var(--color-purple-100);
          border-radius: 50%;
          background-color: var(--color-white);
          &:not(:first-child) {
            margin-left: calc(var(--space-xs) * -1);
          }
          img {
            border-radius: 0;
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
      }
      .name {
        line-height: var(--leading-snug);
        p {
          margin: 0;
          &.author {
            font-size: var(--text-base);
            color: var(--color-gray-900);
            a {
              @include link-no-underline;
              --link-color: var(--color-gray-900);
              font-weight: $font-weight-medium;
            }
          }
          &.article-length {
            font-size: var(--text-sm);
          }
        }
      }
    }
  }
}
.category-featured-image,.sponsorship-image {
  @include wp-full-width-no-padding;
  background: var(--gradient-purple-light);
//   margin-top: calc(var(--space-lg)*-1);
  margin-bottom: var(--space-lg);
  min-height: 100px;
  aspect-ratio: 8/1;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 100%;
}
.sponsorship-image {
  margin-bottom: 0;
}
.post-featured-image {
  @include wp-full-width-no-padding;
  margin-top: calc(var(--space-2xl) * -1);
  margin-bottom: var(--space-xl);
  figure {
    margin: 0;
    aspect-ratio: 16/9;
    overflow: hidden;
    border-radius: $border-radius-jumbo-img;
    img,svg {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}
