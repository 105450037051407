html {
  font-size: 16px;
  height: 100%;
  scroll-behavior: smooth;
  body {
    line-height: var(--leading-relaxed);
    background: var(--color-body-bg);
    color: var(--color-body-text);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
a {
  @include transition($link-transition);
  color: var(--link-color);
  &:hover {
    color: var(--link-color-hover);
  }
  &:active {
    color: var(--link-color-active);
  }
  &:focus {
    color: var(--link-color-focus);
  }
  &:focus-visible {
    outline: 2px solid var(--link-color-focus-visible);
    border-radius: 4px;
  }
}
img,svg {
  @include img-fluid;
}




/* FONTS
  --------------------------------------- */
h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  letter-spacing: var(--tracking-tight);
  margin: var(--gap-md) 0;
}
h1,.h1 {
  @include serif-text;
  line-height: var(--leading-none);
}
p,ol,ul {
  margin: 0 0 var(--gap-base) 0;
}
.overline {
  @include overline;
}




.alert-warning {
  color: var(--color-purple);
  background-color: var(--color-teal-50);
  border-color: var(--color-teal-50);
}
