/* GRID SETTINGS
  --------------------------------------- */
$grid-breakpoints: (
  xs: 0,
  sm: 640px,
  md: 768px,
  lg: 1024px,
  xl: 1280px
);
$container-max-widths: (
  sm: 640px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
);
$container-fluid-max: 1376px;
$grid-gutter-widths: (
  xs: 24px,
  sm: 24px,
  md: 24px,
  lg: 32px,
  xl: 48px
);
$grid-container-margins: (
  xs: 24px,
  sm: 24px,
  md: 36px,
  lg: 48px,
  xl: 48px
);
$main-sm-columns:       12;
$sidebar-sm-columns:    4;


/* COLORS
  --------------------------------------- */
$body-color:            var(--color-body);
$body-bg:               var(--color-bg);
$brand-primary:         var(--color-brand);

$white:                 #fff;

$link-color:            var(--link-color);
$link-hover-color:      var(--link-color-hover);
$link-color-hover:      var(--link-color-hover);
$link-color-active:     var(--link-color-active);
$link-color-focus:      var(--link-color-focus);
$link-decoration:       none;
$link-hover-decoration: underline;
$link-transition:       all .1s ease-in-out;
$link-transition-btn:   padding .15s ease-in-out, all .15s ease-in-out;
$link-transition-move:  transform .15s ease-in-out;
$link-transition-bars:  opacity .2s, transform .2s;



/* FONTS
  --------------------------------------- */
$font-family-sans:      var(--font-sans);
$font-family-serif:     var(--font-serif);
$font-family-awesome:   'Font Awesome 6 Pro';
$font-family-base:      $font-family-sans;

$font-size-base:        1rem;             //  16
/*
$font-size-sm:          var(--text-sm);   //  14
$font-size-lg:          var(--text-lg);   //  18
*/

$h1-font-size:          var(--text-5xl);  //  48
$h2-font-size:          var(--text-4xl);  //  36
$h3-font-size:          var(--text-2xl);  //  24
$h4-font-size:          var(--text-xl);   //  20
$h5-font-size:          var(--text-lg);   //  18
$h6-font-size:          var(--text-lg);   //  18


$font-weight-thin:      var(--font-thin);
$font-weight-extralight:var(--font-extralight);
$font-weight-light:     var(--font-light);
$font-weight-normal:    var(--font-normal);
$font-weight-medium:    var(--font-medium);
$font-weight-semibold:  var(--font-semibold);
$font-weight-bold:      var(--font-bold);
$font-weight-extrabold: var(--font-extrabold);
$font-weight-black:     var(--font-black);
$font-weight-base:      $font-weight-normal;


$display-font-family:   $font-family-serif;
$headings-font-family:  $font-family-sans;
$headings-font-weight:  $font-weight-medium;
$headings-line-height:  var(--leading-tight);

$border-radius-authors:   6px;
$border-radius-elevation: 8px;
$border-radius-post-img:  16px;
$border-radius-jumbo-img: clamp(8px, 1vw, 16px);

/* FORM INPUTS
  --------------------------------------- */
$input-font-size:                var(--text-base);
$input-font-weight:              var(--font-normal);
$input-line-height:              1;
$input-height:                   auto;
$input-bg:                       var(--input-bg);
$input-disabled-bg:              var(--color-gray-200);
$input-color:                    var(--input-color);
$input-border-color:             var(--input-border-color);
$input-border-width:             1px;
$input-box-shadow:               none;
$input-border-radius:            var(--input-border-radius);
$input-border-radius-lg:         $input-border-radius;
$input-border-radius-sm:         $input-border-radius;
$input-focus-box-shadow:         none;
$input-placeholder-color:        var(--input-placeholder);

/* ALERTS
  --------------------------------------- */
$alert-border-radius:            $border-radius-elevation;
$alert-link-font-weight:         var(--font-medium);
