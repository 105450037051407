.content-info {
  @include logo-sizing;
  padding: var(--space-lg) 0 var(--space-sm);
  a {
    @include link-no-underline;
  }
  .primary-info {
    margin-bottom: var(--space-xl);
    .brand {
      @include media-breakpoint-down(md) {
        margin-bottom: var(--space-lg);
      }
    }
    nav {
      ul {
        @include list-normalize;
        column-count: 2;
        column-gap: var(--gap-lg);
        li {
          line-height: var(--leading-none);
          margin-bottom: var(--gap-base);
          break-inside: avoid;
          a {
            @include opacity-75;
          }
        }
        @include media-breakpoint-up(md) {
          column-count: 3;
          li {
            margin-bottom: var(--gap-md);
          }
        }
      }
    }
  }
  .secondary-info {
    text-align: center;
    @include media-breakpoint-up(md) {
      flex-direction: row-reverse;
      text-align: inherit;
    }
    &>div {
      margin-top: var(--space-sm);
      &.tagline-copyright {
        p {
          margin: 0;
          &.copyright {
            @include opacity-75;
            color: var(--color-purple-100);
          }
          &.tagline {
            @include serif-text;
            font-size: var(--text-xl);
            @include media-breakpoint-down(md) {
              margin-bottom: .25em;
            }
          }
          @include media-breakpoint-up(lg) {
            display: inline-block;
            &.tagline {
              margin-right: .5em;
            }
          }
        }

      }
      &.social {
        nav {
          @include media-breakpoint-up(md) {
            text-align: right;
          }
          ul {
            @include list-normalize-inline-block;
            li {
              margin: 0 map-get($grid-gutter-widths, 'xs')/2;
              @include media-breakpoint-up(md) {
                margin: 0 0 0 map-get($grid-gutter-widths, 'xs');
              }
              a {
                display: block;
                text-align: center;
                background-color: rgba($white,.15);
                color: var(--color-white);
                opacity: 1;
                width: calc(var(--static-size-base)*2.25);
                height: calc(var(--static-size-base)*2.25);
                line-height: calc(var(--static-size-base)*2.25);
                border-radius: 50%;
                i {
                  font-size: var(--static-size-base);
                }
                @include hover-focus {
                  background-color: rgba($white,.2);
                }
              }
            }
          }
        }
      }
    }
  }
}
.cky-classic-bottom {
  margin-bottom: var(--space-sm);
  padding: 0 2rem;
  .cky-consent-bar {
    @include elevation('md');
    max-width: 1280px;
    position: relative;
    background-color: var(--color-white);
    margin: 0 auto;
    padding: var(--gap-base) !important;
    padding-left: var(--gap-xl) !important;
    @include media-breakpoint-down(xs) {
      padding: var(--gap-xs) !important;
    }
    .cky-banner-btn-close {

    }
    .cky-notice {
      .cky-title {
        display: none;
      }
      .cky-notice-group {
        @include d-flex;
        width: 100%;
        align-items: center;
        .cky-notice-des {
          @include last-child-zero-margin;
          flex-grow: 1;
          padding-right: var(--gap-xl);
          color: var(--color-text) !important;
          font-size: inherit;
          line-height: inherit;
          * {
            font-size: inherit;
            max-width: 710px;
          }
        }
        .cky-notice-btn-wrapper {
          .cky-btn {
            @extend .btn;
            @extend .btn-sm;
            padding: 0.7rem 1rem;
            background-color: var(--color-purple-800) !important;
            border-color: var(--color-purple-800) !important;
            color: var(--color-white) !important;
            &:hover {
              opacity: 1;
              background-color: var(--color-purple-600) !important;
              border-color: var(--color-purple-600) !important;
            }
            &:active,&:focus,&:focus-visible {
              background-color: var(--color-purple-700) !important;
              border-color: var(--color-purple-700) !important;
            }
          }
        }
        @include media-breakpoint-down(xs) {
          flex-direction: column;
          .cky-notice-des {
            width: 100%;
            padding: 0;
          }
          .cky-notice-btn-wrapper {
            margin-top: var(--gap-xs);
            width: 100%;
            padding: 0;
            .cky-btn {
              width: 100%;
            }
          }
        }

      }
    }
  }
}
.gdpr-block {
  width: 100%;
  position: fixed;
  bottom: 0;
  margin-bottom: var(--space-sm);
  padding: 0 2rem;
  .gdpr-wrap {
    @include elevation('md');
    align-items: center;
    max-width: 1280px;
    margin: 0 auto;
    padding: var(--gap-base);
    padding-left: var(--gap-xl);
    .description {
      flex-grow: 1;
      padding-right: var(--gap-xl);
      article {
        @include last-child-zero-margin;
        max-width: 710px;
      }
      .accept {

      }
    }
    .action {
      .btn {
        padding: 0.7rem 1rem;
      }
    }
    @include media-breakpoint-down(xs) {
      padding: var(--gap-xs);
      flex-direction: column;
      .description {
        width: 100%;
      }
      .action {
        margin-top: var(--gap-xs);
        width: 100%;
        .btn {
          width: 100%;
        }
      }
    }
  }
}
