.form-control {
  @include sans-text;
  padding: var(--gap-base) var(--gap-md);
  border: 1px solid var(--input-border-color);
  height: auto;
  &:focus,&:focus-visible {
    border-color: var(--link-color-focus-visible);
  }
  &:focus {
		&::-webkit-input-placeholder {
		  color: transparent;
		}
		&:-moz-placeholder { /* Firefox 18- */
		  color: transparent;
		}
		&::-moz-placeholder {  /* Firefox 19+ */
		  color: transparent;
		}
		&:-ms-input-placeholder {
		  color: transparent;
		}
	}
}

// Search form
.search-form {
  label {
    flex-grow: 1;
    margin-right: var(--gap-xs);
    margin-bottom: 0;
    input {
      width: 100% !important;
    }
  }
  &.form-inline {
    @include media-breakpoint-down(sm) {
      display: block;
      label {
        display: block;
        margin: 0 0 var(--gap-xs);
      }
      .btn {
        width: 100%;
      }
    }
  }
}
