header.banner {
  @include logo-sizing;
  a {
    @include link-no-underline;
  }
  .brand-block {
    padding: var(--gap-lg) 0;
    @include media-breakpoint-up(lg) {
      padding: var(--space-sm) 0 var(--space-sm);
    }
    .corporate-link {
      margin-right: calc(var(--gap-2xl) * 1.5);
      @include media-breakpoint-up(xl) {
        margin-right: calc(var(--gap-2xl) * 2);
      }
    }
    .brand {
      position: relative;
      @include media-breakpoint-down(md) {
        justify-content: space-between;
      }
      .nav-corporate-mobile {
        position: fixed;
        width: 100%;
        max-height: 100%;
        top:0;
        left: 0;
        z-index: 20;
        nav {
          @include elevation('base');
          margin: var(--gap-xs);
          padding: var(--gap-base);
          @include media-breakpoint-up(md) {
            padding: var(--gap-base) calc(var(--gap-2xl) - 8px);
          }
        }
        .brand-mobile {
          justify-content: space-between;
          .btn-toggler {
            padding: 2px 4px;
          }
        }
        ul {
          @include list-normalize;
          text-align: center;
          &#menu-corporate-header-mobile {
            margin: var(--space-sm) 0 var(--space-lg);
            line-height: var(--leading-none);
            li {
              margin-bottom: var(--space-sm);
              a {
                --link-color: var(--color-gray-600);
                --link-color-hover: var(--color-gray-800);
                --link-color-active: var(--link-color-hover);
                --link-color-focus: var(--link-color-hover);
                opacity: 1;
                font-size: var(--text-2xl);
              }
            }
          }
          &#menu-login-buttons-mobile {
            margin-bottom: var(--space-xs);
            li {
              margin-bottom: var(--space-xs);
              .btn {
                min-width: 280px;
                @include media-breakpoint-up(sm) {
                  min-width: 320px;
                }
                &.btn-info {
                  --btn-bg: var(--color-purple-50);
                  --btn-bg-hover: var(--btn-bg);
                  --btn-bg-active: var(--btn-bg);
                  --btn-bg-focus: var(--btn-bg);
                  --btn-bg-focus-active: var(--btn-bg);
                }
              }
            }
          }
        }
      }

    }
    .navigations {
      justify-content: space-between;
      flex-grow: 1;
      .nav-corporate {
        ul {
          @include list-normalize-inline-block;
          li {
            margin: 0 var(--gap-xl) 0 0;
            @include media-breakpoint-up(xl) {
              margin-right: var(--gap-2xl);
            }
            a {
              @include opacity-75;
            }
          }
        }
      }
      .nav-login {
        ul {
          @include list-normalize-inline-block;
          li {
            margin: 0 0 0 var(--gap-base);
            .btn {
              padding: 0.7rem 1rem;
              border-width: 0 !important;
            }
          }
        }
      }
    }
  }
  .stories-block {
    nav.primary-menu {
      --link-color: var(--color-gray-600);
      border-bottom: 1px solid var(--color-gray-300);
      justify-content: space-between;
      position: relative;
      ul.category-links {
        @include list-normalize-inline-block;
        line-height: var(--leading-none);
        margin-bottom: -1px; // to have border-bottoms overlap
        li {
          margin: 0;
          a {
            @include link-no-underline;
            display: inline-block;
            height: 100%;
            padding: var(--gap-md) var(--gap-md) var(--gap-md-s);
            border-bottom: 2px solid transparent;
            --link-color-hover: var(--color-purple-800);
            --link-color-active: var(--link-color-hover);
            --link-color-focus: var(--link-color-hover);
            &:hover, &:focus, &:active {
              background-color: var(--color-purple-200);
            }
            @include media-breakpoint-up(xl) {
              padding: var(--gap-md) var(--gap-lg) var(--gap-md-s);
            }
          }
          body.archive & {
            &.active:not(.current-post-ancestor):not(.current-post-parent):not(.latest) {
              a {
                border-bottom-color: var(--link-color-active);
                color: var(--link-color-active);
                background-color: transparent !important;
              }
            }
          }
          body.blog & {
            &.active:not(.current-post-ancestor):not(.current-post-parent) {
              a {
                border-bottom-color: var(--link-color-active);
                color: var(--link-color-active);
                background-color: transparent !important;
              }
            }
          }
        }
      }
      ul.expand-navs {
        @include list-normalize-inline-block;
        line-height: var(--leading-none);
        .has-icon {
          white-space: nowrap;
          [class*=fa-] {
            margin-left: var(--gap-xs);
          }
        }
        li:last-child {
          margin-left: var(--gap-2xs);
        }
        @include media-breakpoint-down(md) {
          margin: var(--gap-xs) 0;
          width: 100%;
          justify-content: flex-end;
          li:first-child {
            margin-right: auto;
          }
        }
      }
      .expander {
        @include elevation('md');
        position: absolute;
        width: 100%;
        left: 0;
        top: 100%;
        margin-top: var(--gap-xs);
        z-index: 10;
        &.expanded-menu-wrap {
          padding: 0 var(--space-md);
          font-size: var(--text-sm);
          h2 {
            margin: var(--space-sm) 0 var(--gap-lg);
            color: var(--color-gray-900);
          }
          ul {
            @include list-normalize;
            margin-bottom: var(--space-md);
            column-count: 2;
            column-gap: var(--gap-lg);
            @include media-breakpoint-up(md) {
              column-count: 3;
            }
            @include media-breakpoint-up(lg) {
              column-count: 4;
            }
            li {
              margin-bottom: var(--gap-base);
              break-inside: avoid;
            }
          }
        }
        &.searchbar {
          padding: 0 var(--space-sm);
          form {
            margin: calc(var(--space-xs) * 1.5) 0;
          }
        }
      }

    }
  }
}
body.primary-nav-engaged {
  overflow: hidden;
}
