/* GRID
  --------------------------------------- */
@mixin wp-full-width-no-padding {
  width: 100vw;
  max-width: 100vw;
  margin: 0;
  margin-left: calc(50% - 50vw);
}


/* COLORS
  --------------------------------------- */
@mixin thrive-gradient($color) {
  background: var(--gradient-#{$color});
}
@mixin opacity-75 {
  opacity: .75;
  &:is(a) {
    @include hover-focus {
      opacity: 1;
    }
  }
}



/* ELEMENTS
  --------------------------------------- */
@mixin d-flex {
  display: flex !important;
}
@mixin list-normalize {
  padding: 0;
  list-style: none;
  margin: 0;
}
@mixin list-normalize-inline-block {
  @include list-normalize;
  &>li {
    display: inline-block;
  }
}
@mixin last-child-zero-margin {
  &>:last-child {
    margin-bottom: 0;
  }
}
@mixin link-underline {
  text-decoration: underline;
  @include hover-focus {
    text-decoration: underline;
  }
}
@mixin link-no-underline {
  @include hover-focus {
    text-decoration: none;
  }
}
@mixin link-color-brand-primary {
  --link-color: var(--color-purple-600);
  --link-color-hover: var(--color-purple-700);
  --link-color-active: var(--color-purple-800);
  --link-color-focus: var(--color-purple-800);
}
@mixin link-color-brand-secondary {
  --link-color: var(--color-teal);
}

@mixin elevation($size) {
  box-shadow: var(--elevation-#{$size}) !important;
  border-radius: $border-radius-elevation;
}
@mixin logo-sizing {
  .brand,.brand-mobile {
    a.corporate-link {
      display: block;
      width: 228px;
      opacity: 1;
      @include media-breakpoint-up(md) {
        width: 304px;
      }
      img, svg {
        @include img-fluid;
      }
    }
  }
  &.content-info {
    .brand {
      a.corporate-link {
        @include media-breakpoint-only(md) {
          width: 285px;
        }
      }
    }
  }
}
@mixin tile-hover {
  figure {
    overflow: hidden;
    img {
      transition: all ease .5s;
    }
  }
  &:hover,&:active,&:focus,&:focus-visible {
    figure {
      img {
        transform: scale(1.1);
      }
    }
  }
}



/* FONTS
  --------------------------------------- */
@mixin sans-text {
  font-family: $font-family-sans;
}
@mixin serif-text {
  letter-spacing: var(--tracking-tight);
  line-height: var(--leading-tight);
  font-family: $font-family-serif;
  font-feature-settings: 'pnum' on, 'lnum' on;
}
@mixin overline {
  @include sans-text;
  letter-spacing: var(--tracking-widest);
  line-height: var(--leading-tight);
  font-weight: $font-weight-medium;
  text-transform: uppercase;
}
