// Grid system
.container-fluid {
  max-width: map-get($container-max-widths, xl) !important;
  @media (min-width: 1536px) {
    padding: 0;
  }
}
.align-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.main {
  @include make-col-ready();
  @include media-breakpoint-up(sm) {
    @include make-col($main-sm-columns);
    .sidebar-primary & {
      @include make-col($main-sm-columns - $sidebar-sm-columns);
    }
  }
}
.sidebar {
  @include make-col-ready();
  @include media-breakpoint-up(sm) {
    @include make-col($sidebar-sm-columns);
  }
}
main#main-content {
  margin-bottom: var(--space-xl);
}
