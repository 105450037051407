.single-post {
  article.hentry {
    .entry-content {
      h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
        color: var(--color-gray-900);
      }
    }
    footer {
      @include last-child-zero-margin;
      padding-top: var(--space-md);
      ul.tags {
        @include list-normalize-inline-block;
        margin-bottom: var(--space-xs);
        &>li {
          margin: 0 var(--gap-xs) var(--gap-xs) 0;
        }
      }
      time.updated {
        margin-bottom: var(--space-md);
        font-size: var(--text-sm);
        color: var(--color-gray-600);
      }
    }
  }
}
.thrive-authors-wrap {
  .pp-multiple-authors-boxes-ul {
    margin-bottom: 0;
    &>li {
      margin: 0;
      padding: calc(var(--space-sm) * 2);
      text-align: left;
      .author &,.tax-author & {
        @include elevation('base');
      }
      &:not(:first-child) {
        margin-top: var(--space-sm);
      }
      border: none;
      font-size: var(--text-sm);
      border-radius: $border-radius-authors;
      .author-overview {
        figure {
          @include last-child-zero-margin;
          width: var(--avatar-size);
          margin-right: var(--space-xs);
          margin-bottom: 0;
          aspect-ratio: 1/1;
          overflow: hidden;
          border-radius: calc(var(--avatar-size)/2);
          border: 2px solid var(--color-purple-50);
          background-color: var(--color-white);
          img {
            border-radius: 0;
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
        .pp-author-boxes-name {
          @include last-child-zero-margin;
          margin-bottom: 0;
          color: var(--color-black);
          p {
            margin-bottom: 0;
            &+p {
              margin-top: -.2em;
            }
          }
          h3 {
            font-size: var(--text-base);
            margin: 0;
            a {
              @include link-no-underline;
              --link-color: var(--color-black);
            }
          }
        }
      }
      .author-links {
        margin-top: var(--space-sm);
        ul {
          @include list-normalize-inline-block;
          li {
            margin: 0 var(--gap-xs) 0 0;
            padding: 0;
            border: none;
            min-height: 0;
            a {
              display: block;
              text-align: center;
              background-color: var(--color-teal-200);
              color: var(--color-teal-800);
              opacity: 1;
              width: calc(var(--static-size-base)*2.25);
              height: calc(var(--static-size-base)*2.25);
              line-height: calc(var(--static-size-base)*2.25);
              border-radius: 50%;
              i {
                font-size: var(--static-size-base);
              }
              @include hover-focus {
                background-color: var(--color-teal-300);
              }
            }
          }
        }


      }
      .author-description {
        @include last-child-zero-margin;
        margin-top: var(--space-sm);
        .multiple-authors-description {
          margin: 0;
        }
      }
    }
  }

}
.share-buttons-wrap {
  @include thrive-gradient('purple-faint');
  margin-bottom: var(--space-md);
  padding: var(--gap-sm) var(--gap-lg);
  border-radius: $border-radius-elevation;
  justify-content: space-between;
  color: var(--color-purple-600);
  .socializer {
    a {
      @extend .btn;
      @extend .btn-tertiary;
      @extend .btn-icon;
      font-size: var(--text-lg) !important;
      --btn-bg-hover: var(--color-purple-100);
      --btn-bg-active: var(--btn-bg-hover);
      --btn-bg-focus: var(--btn-bg-hover);
      --btn-bg-focus-active: var(--btn-bg-hover);
      [class*=fa-] {
        font-size: var(--text-lg) !important;
        line-height: var(--text-lg) !important;
        width: var(--text-lg) !important;
        height: var(--text-xl) !important;
      }
    }
  }
}
.author-archive-title {
  margin: 0 0 var(--space-lg);
  color: var(--color-gray-900);
  text-align: center;
}
.articles-grid {
  article.hentry {
    margin-bottom: var(--space-md);
    a.article-link {
      @include link-no-underline;
      @include last-child-zero-margin;
      @include tile-hover;
      height: 100%;
      figure {
        margin-bottom: calc(var(--space-md) * .5);
        border-radius: $border-radius-post-img/2;
        aspect-ratio: 16/9;
        overflow: hidden;
        border-radius: $border-radius-post-img/2;
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
      h2 {
        @include transition($link-transition);
        color: var(--color-gray-900);
        margin: 0 0 var(--space-xs);
      }
      time {
        @include transition($link-transition);
        @include overline;
        font-size: var(--text-sm);
        color: var(--color-gray-600);
      }
      @include hover-focus {
        h2 {
          color: var(--link-color);
        }
        time {
          color: var(--color-gray-700);
        }
      }
    }
  }
}
.pagination {
  margin-top: var(--space-lg);
  .nav-links {
    width: 100%;
    justify-content: center;
    &>a,&>span {
      @extend .btn;
      @extend .btn-tertiary;
      padding: var(--gap-sm) var(--gap-base) !important;
      margin: 0 var(--gap-2xs);
      border-radius: 100px !important;
      &.inactive {
        opacity: .25;
      }
      &.prev,&.next,&.dots {
        padding: var(--gap-sm) calc(var(--gap-sm)*1.1) !important;
      }
      &.prev {
        margin-left: 0;
        margin-right: auto;
      }
      &.next {
        margin-right: 0;
        margin-left: auto;
      }
      @include media-breakpoint-down(sm) {
        font-size: var(--text-sm) !important;
        margin: 0 calc(var(--gap-2xs)*.75);
      }
    }
    &>span {
      cursor: default !important;
    }
  }
}
