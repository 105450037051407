@font-face {
	font-family: GT-America; src:
		url("https://thriveglobal.com/assets/GT-America/GT-America-LCG-Standard-Regular.ttf") format("ttf"),
		url("https://thriveglobal.com/assets/GT-America/GT-America-LCG-Standard-Regular.woff") format("woff"),
		url("https://thriveglobal.com/assets/GT-America/GT-America-LCG-Standard-Regular.woff2") format("woff2");
	font-style: normal; font-weight: 400;
}
@font-face { font-family: GT-America; src:
		url("https://thriveglobal.com/assets/GT-America/GT-America-LCG-Standard-Medium.ttf") format("ttf"),
		url("https://thriveglobal.com/assets/GT-America/GT-America-LCG-Standard-Medium.woff") format("woff"),
		url("https://thriveglobal.com/assets/GT-America/GT-America-LCG-Standard-Medium.woff2") format("woff2");
	font-style: normal;
	font-weight: 500;
}
@font-face { font-family: GT-Super-Display; src:
		url("https://thriveglobal.com/assets/GT-Super-Display/GT-Super-Display-Regular.ttf") format("ttf"),
		url("https://thriveglobal.com/assets/GT-Super-Display/GT-Super-Display-Regular.woff") format("woff"),
		url("https://thriveglobal.com/assets/GT-Super-Display/GT-Super-Display-Regular.woff2") format("woff2");
	font-style: normal;
	font-weight: 400;
}
@font-face { font-family: GT-Super-Display; src:
		url("https://thriveglobal.com/assets/GT-Super-Display/GT-Super-Display-Medium.ttf") format("ttf"),
		url("https://thriveglobal.com/assets/GT-Super-Display/GT-Super-Display-Medium.woff") format("woff"),
		url("https://thriveglobal.com/assets/GT-Super-Display/GT-Super-Display-Medium.woff2") format("woff2");
	font-style: normal;
	font-weight: 500;
}
@font-face { font-family: GT-Super-Display; src:
		url("https://thriveglobal.com/assets/GT-Super-Display/GT-Super-Display-Medium-Italic.ttf") format("ttf"),
		url("https://thriveglobal.com/assets/GT-Super-Display/GT-Super-Display-Medium-Italic.woff") format("woff"),
		url("https://thriveglobal.com/assets/GT-Super-Display/GT-Super-Display-Medium-Italic.woff2") format("woff2");
	font-style: italic;
	font-weight: 500;
}
@font-face { font-family: GT-Super-Display; src:
		url("https://thriveglobal.com/assets/GT-Super-Display/GT-Super-Display-Bold.ttf") format("ttf"),
		url("https://thriveglobal.com/assets/GT-Super-Display/GT-Super-Display-Bold.woff") format("woff"),
		url("https://thriveglobal.com/assets/GT-Super-Display/GT-Super-Display-Bold.woff2") format("woff2");
	font-style: normal;
	font-weight: 700;
}
