// WordPress Generated Classes
// http://codex.wordpress.org/CSS#WordPress_Generated_Classes

// Media alignment
.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  height: auto;
}
.aligncenter {
  display: block;
  margin: ($spacer / 2) auto;
  height: auto;
}
.alignleft,
.alignright {
  margin-bottom: ($spacer / 2);
  height: auto;
}
@include media-breakpoint-up(sm) {
  // Only float if not on an extra small device
  .alignleft {
    float: left;
    margin-right: ($spacer / 2);
  }
  .alignright {
    float: right;
    margin-left: ($spacer / 2);
  }
}
.wp-block-image figure, figure.wp-block-image {
  margin-top: var(--space-md);
  margin-bottom: var(--space-md);
  figcaption {
    margin-top: var(--space-xs);
    padding-left: var(--gap-xs);
    border-left: 2px solid var(--caption-color);
    color: --caption-color;
    font-size: var(--text-xs);
  }
}


figure.wp-block-pullquote {
  margin: 0;
  padding: 0;
}
.wp-block-quote, figure.wp-block-pullquote blockquote {
  @include last-child-zero-margin;
  @include serif-text;
  font-size: var(--text-3xl);
  color: var(--color-brand-primary);
  margin: var(--gap-lg) 0 var(--gap-xl);
  padding: 0 0 0 var(--gap-lg);
  border-left: 2px solid var(--color-brand-primary);
  text-align: left;
  p {
    font-size: inherit;
    line-height: inherit;
  }
  cite {
    @include sans-text;
    font-size: var(--text-sm);
    font-style: normal;
    display: block;
  }
}

// Captions
.wp-caption {
  @extend .figure;
}
.wp-caption > img {
  @extend .figure-img;
  @extend .img-fluid;
}
.wp-caption-text {
  @extend .figure-caption;
}
.wp-caption-text {
  @extend .figure-caption;
}

// Text meant only for screen readers
.screen-reader-text {
  @extend .sr-only;
  @extend .sr-only-focusable;
}
