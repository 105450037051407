/* GENERAL BLOCKS / COLORS
  --------------------------------------- */
.wp-full-width {
  @include wp-full-width-no-padding;
}
.bg-purple {
  --color-body-bg: var(--color-purple);
  --color-body-text: var(--color-white);
}
.bg-purple-dark {
  --color-body-bg: var(--color-purple-900);
  --color-body-text: var(--color-white);
  --link-color: var(--color-purple-100);
  --link-color-hover: var(--color-white);
  --link-color-active: var(--color-white);
  --link-color-focus: var(--color-white);
}
.bg-purple-light {
  --color-body-bg: var(--color-purple-50);
}
.bg-teal {
  --color-body-bg: var(--color-teal);
  --color-body-text: var(--color-white);
}
.bg-teal-50 {
  --color-body-bg: var(--color-teal-50);
}

.bg-white {
  .btn {
    &.btn-toggler {
      --btn-color: var(--color-gray-700);
      --btn-color-hover: var(--color-gray-800);
      --btn-color-active: var(--btn-color-hover);
      --btn-color-focus: var(--btn-color-hover);
      opacity: 1;
      &:focus-visible {
        background-color: $white !important;
      }
    }
  }
}
[class*="bg-"]:not(.socializer) {
  background: var(--color-body-bg);
  color: var(--color-body-text);
}
