:root {
  --btn-color: var(--color-white);
  --btn-color-hover: var(--color-white);
  --btn-color-active: var(--color-white);
  --btn-color-focus: var(--color-white);
  --btn-bg: var(--color-purple-800);
  --btn-bg-hover: var(--color-purple-600);
  --btn-bg-active: var(--color-purple-700);
  --btn-bg-focus: var(--btn-bg-active);
  --btn-bg-focus-active: var(--btn-bg-active);
  --btn-border-base: transparent;
  --btn-border-hover: var(--btn-border-base);
  --btn-border-active: var(--btn-border-base);
  --btn-border-focus: var(--btn-border-base);
  --btn-border-focus-visible: var(--color-white);
  --btn-outline-focus-visible: var(--link-color-focus-visible);
}
.btn,.btn:not(:disabled):not(.disabled) {
  @include transition($link-transition);
  @include sans-text;
  font-size: var(--text-base);
  font-weight: var(--font-medium);
  line-height: var(--leading-none);
  letter-spacing: var(--tracking-tight);
  box-shadow: none !important;
  padding: var(--gap-base) var(--gap-md);
  border: 2px solid var(--btn-border-base) !important;
  border-radius: 6px;
  position: relative;
  color: var(--btn-color);
  background-color: var(--btn-bg);
  &:hover {
    color: var(--btn-color-hover);
    background-color: var(--btn-bg-hover);
    border-color: var(--btn-border-hover);
  }
  &:active {
    color: var(--btn-color-active);
    background-color: var(--btn-bg-active);
    border-color: var(--btn-border-active);
  }
  &:focus {
    color: var(--btn-color-focus);
    background-color: var(--btn-bg-focus);
    border-color: var(--btn-border-focus);
  }
  &:focus-visible {
    color: var(--btn-color-focus);
    background-color: var(--btn-bg-focus-active);
    outline: 2px solid var(--btn-outline-focus-visible);
    border-color: var(--btn-border-focus-visible);

  }
  &.btn-transparent {
    --btn-color: var(--color-gray-600);
    --btn-color-hover: var(--color-purple-800);
    --btn-color-active: var(--color-white);
    --btn-color-focus: var(--color-white);
    --btn-bg: transparent;
    --btn-bg-hover: var(--color-purple-200);
    --btn-bg-active: var(--color-purple-800);
    --btn-bg-focus: var(--color-purple-800);
    &.expanded {
      --btn-color: var(--color-white);
      --btn-color-hover: var(--btn-color);
      --btn-color-active: var(--btn-color);
      --btn-color-focus: var(--btn-color);
      --btn-bg: var(--color-purple-800);
      --btn-bg-hover: var(--btn-bg);
      --btn-bg-active: var(--btn-bg);
      --btn-bg-focus: var(--btn-bg);
    }
  }
  &.btn-secondary {
    --btn-bg: var(--color-teal-600);
    --btn-bg-hover: var(--color-teal-700);
    --btn-bg-active: var(--color-teal-800);
    --btn-bg-focus: var(--btn-bg-active);
  }
  &.btn-tertiary {
    --btn-color: var(--color-purple-800);
    --btn-color-hover: var(--color-purple-600);
    --btn-color-active: var(--color-purple-700);
    --btn-color-focus: var(--btn-color-active);
    --btn-bg: var(--color-purple-50);

    --btn-bg-hover: var(--color-purple-100);
    --btn-bg-active: var(--btn-bg-hover);
    --btn-bg-focus: var(--btn-bg-hover);
    --btn-bg-focus-active: var(--btn-bg-hover);
/*
    --btn-bg-hover: var(--btn-bg);
    --btn-bg-active: var(--btn-bg);
    --btn-bg-focus: var(--btn-bg);
    --btn-bg-focus-active: var(--btn-bg);
*/
    &.current {
      --btn-color: var(--color-white);
      --btn-color-hover: var(--btn-color);
      --btn-color-active: var(--btn-color);
      --btn-color-focus: var(--btn-color);
      --btn-bg: var(--color-purple);
    }
  }
  &.btn-info,&.btn-white {
    --btn-color: var(--color-purple-800);
    --btn-color-hover: var(--color-purple-600);
    --btn-color-active: var(--color-purple-700);
    --btn-color-focus: var(--btn-color-active);
    --btn-bg: var(--color-white);
    --btn-bg-hover: var(--btn-bg);
    --btn-bg-active: var(--btn-bg);
    --btn-bg-focus: var(--btn-bg);
    --btn-bg-focus-active: var(--btn-bg);
  }
  &.btn-icon {
    padding: var(--gap-sm);
    border-radius: 50%;
    font-size: var(--text-xl);
    [class*=fa-] {
      width: var(--text-xl);
      line-height: var(--text-xl);
      text-align: center;
    }
  }
  &.btn-lg {
    font-size: var(--text-lg);
    padding: var(--gap-md) var(--gap-3xl);
  }
  &.btn-sm {
    font-size: var(--text-sm);
    padding: var(--gap-sm) var(--gap-base);
  }
  &.btn-toggler {
    border-radius: 4px;
    padding: 2px;
    opacity: .75;
    font-size: var(--text-2xl);
    --btn-bg: transparent;
    --btn-bg-hover: var(--btn-bg);
    --btn-bg-active: var(--btn-bg);
    --btn-bg-focus: var(--btn-bg);
    --btn-bg-focus-active: var(--color-purple-600);
    --btn-border-base: transparent;
    --btn-border-hover: var(--btn-border-base);
    --btn-border-active: var(--btn-border-base);
    --btn-border-focus: var(--btn-border-base);
    &:hover,&:focus,&:active,&:focus-visible {
      opacity: 1;
    }
  }
}
