@function hextorgb($hex) {
  @return red($hex), green($hex), blue($hex);
}

:root {

  /* FONTS/TYPE
  --------------------------------------- */
  /* font-family */
    --font-sans: GT-America;
    --font-serif: GT-Super-Display, times, georgia, serif;
    --font-display: GT-Super-Display, times, georgia, serif;

  /* line-height */
    --leading-none: 1;
    --leading-tight: 1.25;
    --leading-snug: 1.375;
    --leading-relaxed: 1.625;

  /* letter-spacing */
    --tracking-tight: -0.01em;
    --tracking-normal: 0em;
    --tracking-widest: .12em;

  /* font-weight */
    --font-thin: 100;
    --font-extralight: 200;
    --font-light: 300;
    --font-normal: 400;
    --font-medium: 500;
    --font-semibold: 600;
    --font-bold: 700;
    --font-extrabold: 800;
    --font-black: 900;

  /* font-size */
    --text-xs: clamp(10px, calc(10px + (12 - 10) * ((100vw - 375px) / (1600 - 375))), 12px);
    --text-sm: clamp(12px, calc(12px + (14 - 12) * ((100vw - 375px) / (1600 - 375))), 14px);
    --text-base: clamp(14px, calc(14px + (16 - 14) * ((100vw - 375px) / (1600 - 375))), 16px);
    --text-lg: clamp(16px, calc(16px + (18 - 16) * ((100vw - 375px) / (1600 - 375))), 18px);
    --text-xl: clamp(18px, calc(18px + (20 - 18) * ((100vw - 375px) / (1600 - 375))), 20px);
    --text-2xl: clamp(20px, calc(20px + (24 - 20) * ((100vw - 375px) / (1600 - 375))), 24px);
    --text-3xl: clamp(24px, calc(24px + (30 - 24) * ((100vw - 375px) / (1600 - 375))), 30px);
    --text-4xl: clamp(30px, calc(30px + (36 - 30) * ((100vw - 375px) / (1600 - 375))), 36px);
    --text-5xl: clamp(32px, calc(32px + (48 - 32) * ((100vw - 375px) / (1600 - 375))), 48px);
    --text-6xl: clamp(36px, calc(36px + (60 - 36) * ((100vw - 375px) / (1600 - 375))), 60px);
    --text-7xl: clamp(40px, calc(40px + (72 - 40) * ((100vw - 375px) / (1600 - 375))), 72px);
    --text-8xl: clamp(48px, calc(48px + (96 - 48) * ((100vw - 375px) / (1600 - 375))), 96px);
    --text-9xl: clamp(60px, calc(60px + (128 - 60) * ((100vw - 375px) / (1600 - 375))), 128px);


  /* COLORS
  --------------------------------------- */
    --color-white: #fff;
    --color-black: #000;

    --color-purple-50: #ECEBF7;
    --color-purple-100: #EBE3FC;
    --color-purple-200: #E0D3FB;
    --color-purple-300: #CDBAF2;
    --color-purple-400: #AE92E4;
    --color-purple-600: #4D1AAD;
    --color-purple-700: #310583;
    --color-purple-800: #1E0056;
    --color-purple-900: #16003F;
    --color-purple: var(--color-purple-800);

    --color-teal-50: #F0F5F6;
    --color-teal-200: #D6EAEE;
    --color-teal-300: #B6DDE1;
    --color-teal-600: #14808E;
    --color-teal-700: #0b6d79;
    --color-teal-800: #0B5D68;
    --color-teal: var(--color-teal-600);

    --color-gray-200: #DBDDE0;
    --color-gray-300: #BFC2C7;
    --color-gray-600: #6A717D;
    --color-gray-700: #4F5865;
    --color-gray-800: #343E4E;
    --color-gray-900: #172235;
    --color-gray: var(--color-gray-700);

    --color-brand-primary: var(--color-purple);
    --color-brand-secondary: var(--color-teal);
    --color-body-text: var(--color-gray);
    --color-body-bg: var(--color-white);

    --gradient-purple: linear-gradient(144.9deg, #432699 2.68%, #1E0056 95.3%);
    --gradient-purple-light: radial-gradient(49.74% 60.33% at 50% 81.52%, #E0D3FB 0%, rgba(224, 211, 251, 0.0555757) 78.83%, rgba(224, 211, 251, 0) 100%), #ECEBF7;
    --gradient-purple-faint: linear-gradient(90.56deg, rgba(236, 235, 247, 0.5) 0.49%, rgba(236, 235, 247, 0) 106.66%);
    --gradient-gray: linear-gradient(153.07deg, rgba(255, 255, 255, 0.8) 8.69%, rgba(255, 255, 255, 0) 64.27%), linear-gradient(130.28deg, rgba(235, 233, 255, 0.98) 2.45%, #F1EEE9 95%);

    --link-color: var(--color-purple-600);
    --link-color-hover: var(--color-purple-700);
    --link-color-active: var(--color-purple-800);
    --link-color-focus: var(--color-purple-800);
    --link-color-focus-visible: var(--color-purple-400);

    --caption-color: var(--color-gray);


  /* INPUTS
  --------------------------------------- */
    --input-color: var(--color-gray-900);
    --input-bg: var(--color-white);
    --input-border-color: var(--color-purple-100);
    --input-border-radius: 6px;
    --input-placeholder: var(--color-gray-700);



  /* MARGINS/PADDING
  --------------------------------------- */
  /* fluid-spacer */
    --space-xs: clamp(12px, calc(12px + (16 - 12) * ((100vw - 375px) / (1600 - 375))), 16px);       // 16
    --space-sm: clamp(20px, calc(20px + (32 - 20) * ((100vw - 375px) / (1600 - 375))), 32px);       // 32
    --space-md: clamp(30px, calc(30px + (48 - 30) * ((100vw - 375px) / (1600 - 375))), 48px);       // 48
    --space-lg: clamp(50px, calc(50px + (80 - 50) * ((100vw - 375px) / (1600 - 375))), 80px);       // 80
    --space-xl: clamp(80px, calc(80px + (128 - 80) * ((100vw - 375px) / (1600 - 375))), 128px);     // 128
    --space-xxl: clamp(130px, calc(130px + (208 - 130) * ((100vw - 375px) / (1600 - 375))), 208px); // 208
    --space-2xl: var(--space-xxl);

  /* static gaps*/
    --static-size-base: 16px;
    --gap-base: var(--static-size-base);        // 16
    --gap-2xs: calc(var(--gap-base) * .25);     // 4
    --gap-xs: calc(var(--gap-base) * .5);       // 8
    --gap-sm: calc(var(--gap-base) * .75);      // 12
    --gap-md-s: calc(var(--gap-base) * 1.125);  // 18
    --gap-md: calc(var(--gap-base) * 1.25);     // 20
    --gap-lg: calc(var(--gap-base) * 1.5);      // 24
    --gap-xl: calc(var(--gap-base) * 2);        // 32
    --gap-2xl: calc(var(--gap-base) * 2.25);    // 36
    --gap-3xl: calc(var(--gap-base) * 3);       // 48

    --avatar-size: 56px;


  /* ELEVATIONS
  --------------------------------------- */
    --elevation-base: 0px 5px 12px rgba(13, 0, 38, 0.08);
    --elevation-md: 0px 8px 16px rgba(13, 0, 38, 0.02), 0px 16px 32px rgba(13, 0, 38, 0.08);
    --elevation-lg: 0px 8px 16px rgba(13, 0, 38, 0.02), 0px 16px 32px rgba(13, 0, 38, 0.04), 0px 24px 48px rgba(13, 0, 38, 0.06), 0px 32px 64px rgba(13, 0, 38, 0.08);
    --elevation-xl: 0px 8px 16px rgba(13, 0, 38, 0.02), 0px 16px 32px rgba(13, 0, 38, 0.04), 0px 24px 48px rgba(13, 0, 38, 0.06), 0px 32px 64px rgba(13, 0, 38, 0.08), 0px 40px 80px rgba(13, 0, 38, 0.1);
    --elevation-2xl: 0px 8px 16px rgba(13, 0, 38, 0.02), 0px 16px 32px rgba(13, 0, 38, 0.04), 0px 24px 48px rgba(13, 0, 38, 0.06), 0px 32px 64px rgba(13, 0, 38, 0.08), 0px 40px 80px rgba(13, 0, 38, 0.1), 0px 48px 96px rgba(13, 0, 38, 0.12);
    --elevation-inner: inset 1px 3px 6px rgba(13, 0, 38, 0.08);

}
